import { createApp } from 'vue'
import App from './App.vue'
import { createAuth0 } from '@auth0/auth0-vue';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueCookies from 'vue-cookies'
const app = createApp(App);

app.config.globalProperties.$globalUrl = 'https://api.secmind.io';

app.use(VueCookies,{expires: '7d'}).use(
    createAuth0({
      domain: "secmind.us.auth0.com",
      clientId: "fpG3hhlu0vhyNKiHgv1HyfRz1FLQ0DAF",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://ask.secmind.io"
      }
    })
  ).use(Antd).mount('#app')
