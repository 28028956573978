<template>
  <a-layout class="layout">
    <a-layout-header class="header">
      <div class="logo">
        SECMIND
      </div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        :style="{ lineHeight: '64px' }"
      >
        <div class="login" v-if="!login_success" @click="login">
          <a-button type="primary" ghost>Login</a-button>
        </div>
        <div class="login" v-if="login_success">
          <a-dropdown>
            <a-avatar :src="picture"></a-avatar>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <!-- <a-menu-item key="0" v-if="!formState.customize" @click="doSomethingWithToken">
                  Usage: 8 / 10
                </a-menu-item> -->
                <a-menu-item key="1" @click="showModal">
                  Setting
                </a-menu-item>
                <a-menu-item key="2" @click="logout">
                  Logout
                </a-menu-item>
              </a-menu>
            </template>
            <template #icon></template>
          </a-dropdown>
        </div>
      </a-menu>
    </a-layout-header>
    <template>·
      <!--Setting-->
      <div>
        <a-modal v-model:visible="visible" title="Setting" @ok="handleOk">
          <template #footer>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Save</a-button>
          </template>
          <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item label="Customize Key">
              <a-tooltip>
                <template #title>Using a custom key allows for unlimited searches.</template>
                <a-switch v-model:checked="formState.customize" />
              </a-tooltip>
            </a-form-item>
            <a-form-item label="OPENAI Key">
              <a-tooltip>
                <template #title>Please fill in your OPENAI Key.</template>
                <a-input type="password" v-model:value="formState.openai_key" :disabled="!formState.customize" />
              </a-tooltip>
            </a-form-item>
            <a-form-item label="Language">
              <a-radio-group v-model:value="formState.language">
                <a-tooltip>
                  <template #title>Responding in English.</template>
                  <a-radio value="English">English</a-radio>
                </a-tooltip>
                <a-tooltip>
                  <template #title>Responding in French.</template>
                  <a-radio value="Français">Français</a-radio>
                </a-tooltip>  
                <a-tooltip>
                  <template #title>Responding in Chinese.</template>
                  <a-radio value="中文">中文</a-radio>
                </a-tooltip>  
              </a-radio-group>
            </a-form-item>
            <a-form-item
              name="select-multiple"
              label="Standard Area"
            >
              <a-tooltip>
                <template #title>Please select the country/region where the business is located.</template>
                <a-select
                  v-model:value="formState['area']"
                  mode="multiple"
                  placeholder="Please select business location."
                  default-value="Europe"
                >
                  <a-select-option value="Europe">Europe</a-select-option>
                  <a-select-option value="America">America</a-select-option>
                  <a-select-option value="China">China</a-select-option>
                  <a-select-option value="Singapore">Singapore</a-select-option>
                  <a-select-option value="India">India</a-select-option>
                </a-select>
              </a-tooltip>  
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </template>
    <a-layout-content :style="!isMobile ? 'padding: 0 50px' : 'padding: 0 10px'">
      <a-breadcrumb style="margin: 16px 0">
      </a-breadcrumb>
      <a-row :style="{ background: '#fff', padding: '24px', height: 'inherit'}">
        <a-col v-show="!isMobile()" :span="6"></a-col>
        <a-col :span="!isMobile() ? 12 : 24">
          <Index msg="SECMIND Bot" @onEmitIndex="onEmitIndex"/>
        </a-col>
        <a-col v-show="!isMobile()" :span="6"></a-col>
      </a-row>
      <a-row :gutter="1" :style="{ background: '#fff', padding: '100px', height: '55vh'}">
        <a-col :span="6" v-show="resultStatus && !isMobile()" v-for="ask in hotAsks" :key="ask.id">
          <a-card :title="ask.question" style="width: 280px" @click="handleCardClick(ask.question)">
            <p>{{ ask.answer }}</p>
          </a-card>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-footer style="text-align: center;padding-top: 20px;">
      <a href="#">Privacy</a>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <a href="#">Terms</a>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import { message } from 'ant-design-vue';
import { defineComponent, ref, reactive, toRaw, watchEffect  } from 'vue';
import { UserOutlined } from '@ant-design/icons-vue';
import Index from './components/Index.vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
//vue-cookies
import VueCookies from 'vue-cookies'

export default defineComponent({
  components: {
    Index
  },
  data() {
    return {
      resultStatus: true,
      hotAsks: []
    }
  },
  setup() {  
    const { loginWithRedirect ,getAccessTokenSilently, user, isAuthenticated,logout } = useAuth0();
    const formState = reactive({
      openai_key: '',
      customize: false,
      language: '中文',
      area: ['China'],
    });
    VueCookies.set('formState', toRaw(formState), '7d')
    // Setting
    const loading = ref(false);
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      loading.value = true;
      console.log('submit!!!', toRaw(formState));
      // formState save to cookie
      VueCookies.set('formState', toRaw(formState), '7d')
      loading.value = false;
      visible.value = false;
    };
    // login success handler
    const handleLoginSuccess = async ()  => {
      const token = await getAccessTokenSilently();
      VueCookies.set('token', token, '1d')
      VueCookies.set('userinfo', user.value, '1d')
      console.log('handleLoginSuccess');
      // reload page
      window.location.reload();
    };
    watchEffect(() => {
    if (isAuthenticated.value) {
        handleLoginSuccess();
      }
    });
    return {
      labelCol: {
        style: {
          width: '150px',
        },
      },
      wrapperCol: {
        span: 14,
      },
      formState,
      loading,
      visible,
      showModal,
      handleOk,
      UserOutlined,
      userinfo: VueCookies.get('userinfo'),
      login_success: VueCookies.get('token') ? true : false,
      picture: VueCookies.get('userinfo') ? VueCookies.get('userinfo').picture : '',
      selectedKeys: ref(['2']),
      login: () => {
        loginWithRedirect();
      },
      logout() {
        // clear cookie
        VueCookies.remove('token')
        VueCookies.remove('formState')
        VueCookies.remove('userinfo')
        logout({ returnTo: window.location.origin })
      },
      user,
      isAuthenticated,
    };
  },
  methods:{
    handleMenuClick(e) {
      console.log('click', e);
    },
    handleLogin(){
      console.log('login');
      message.success('Currently no login is required to use.');
    },
    onEmitIndex(idx) {
      console.log(idx)
      this.resultStatus = false
      console.log('ok')
    },
    handleCardClick(title) {
      console.log(title)
    },
    isMobile(){
      const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i;
      return regex.test(window.navigator.userAgent);
    },
    // Get Hot Ask
    getHotAsk() {
      axios.get(this.$globalUrl+'/hot')
      .then(response => {
        console.log(response.data)
        this.hotAsks = response.data;
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
  mounted(){
    this.getHotAsk();    
  }
});
</script>
<style>
.login {
  float: right;
  margin-right: 24px;
}
.logo {
  width: 120px;
  height: 31px;
  /* background: url('/logo.png') no-repeat center; */
  /* margin: 16px 24px 16px 0; */
  float: left;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}
</style>