<template>
  <a-spin tip="Loading..." :spinning="spinning">
  <div class="index">
    <!-- <h1>{{ msg }}</h1>
    <p class="slogan">
    </p> -->
    <!--Loading-->
    <!--SVG Logo-->
    <div style="display: flex; justify-content: center; align-items: center">
      <img src="logo.png" :width="!isMobile() ? 400 : 200" :height="!isMobile() ? 100 : 50"/>
    </div>

    <div id="search" style="display: flex;justify-content:center;">
          <a-input-search
          v-model:value="inputValue"
          placeholder="Please enter the question you want to ask."
          enter-button="Send"
          size="large"
          @search="search"
        />
    </div>
    <a-comment v-show="resultStatus">
      <template #author><a>SECMIND</a></template>
      <template #avatar>
        <a-avatar src="./avatar_icon.png"/>
      </template>
      <template #content>
        <p>
          {{ result }}
        </p>
        <a-progress v-show="progress" :stroke-color="{from: '#108ee9', to: '#87d068'}" :percent="this.percentage" status="active" />
        <a-button v-show="summarize" :loading="summarize_loading" size="large" @click="getSummarize">Optimize</a-button>
      </template>
      <template #datetime>
        <!-- <a id="time">{{ time }} s</a> -->
      </template>
    </a-comment>

  </div>
  </a-spin>
</template>

<script>
import { defineComponent,ref } from 'vue';
import { notification } from 'ant-design-vue';
import axios from 'axios';
import VueCookies from 'vue-cookies'


export default defineComponent({
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  // inputValue
  data() {
    return {
      inputValue: '',
      loadingStatus: false,
      resultStatus: false,
      result: '',
      time: '',
      token: '',
      source : ref([]),
      isDisabled: false,
      ask_count: 0,
      ask_area_count:0,
      percentage: 1,
      progress: true,
      spinning: false,
      summarize: false,
      summarize_loading: false
    }
  },
  methods: {
      searchOnEnter() {
        this.search()
      },
      // 请求总结接口
      getSummarize(){
        this.summarize_loading = true
        const cookieValue = VueCookies.get('formState')
        const all_result = this.result
        axios.post(`${this.$globalUrl}/summarize`, {
          text: all_result,
          ask: this.inputValue,
          language: cookieValue.language
        }, {
          headers: { 
            Authorization: `Bearer ${VueCookies.get('token')}`
          },
          timeout: 300000
        })
        .then(
          response => {
            this.result = response.data.result.content
            this.summarize = false
            this.summarize_loading = false

          }
        )
      },
      getAsk(cookieValue,model,length){
        const makeRequest = () => {
          axios.get(`${this.$globalUrl}/ask?q=${this.inputValue}&openai_key=${cookieValue.openai_key}&customize=${cookieValue.customize}&language=${cookieValue.language}&area=${cookieValue.area}&model=${model}`, {
            headers: {
              Authorization: `Bearer ${VueCookies.get('token')}`
            },
            timeout: 300000
          })
          .then(response => {
            this.progress = true
            this.resultStatus = true
            this.isDisabled = false
            this.result += response.data.answer.replace(/\n\n/g, '\n')+"\n\n"
            console.log(response.data.elapsed_time)
            this.time = parseFloat(this.time) + parseFloat(response.data.elapsed_time)

            this.spinning = false

            this.ask_count += 1
            this.percentage = (this.ask_count/this.ask_area_count)*100
            if (this.ask_count === length) {
              // this.getSummarize()
              this.summarize = true
              this.progress = false
            }
          })
          .catch(error => {
            console.log(error)
            // retry request after 3 seconds
            setTimeout(makeRequest, 3000)
          })
        }

        makeRequest()
      },
      getNewAsk(cookieValue,model){
        axios.get(`${this.$globalUrl}/newask?q=${this.inputValue}&openai_key=${cookieValue.openai_key}&customize=${cookieValue.customize}&language=${cookieValue.language}&area=${cookieValue.area}&model=${model}`, {
          headers: {
            Authorization: `Bearer ${VueCookies.get('token')}`
          },
          timeout: 300000
        })
        .then(response => {
          this.progress = true
          this.resultStatus = true
          this.isDisabled = false
          this.result += response.data.answer.replace(/\n\n/g, '\n')+"\n\n"
          console.log(response.data.elapsed_time)
          this.time = parseFloat(this.time) + parseFloat(response.data.elapsed_time)
          this.spinning = false
          // disable summarize button
          // this.summarize = true
          this.progress = false
        })
        .catch(error => {
          console.log(error)
          // retry request after 3 seconds
        })
      },
      search() {
        this.ask_count = 0
        this.$emit('onEmitIndex', this.inputValue)
        this.isDisabled = true
        // true
        this.spinning = true
        this.resultStatus = false
        this.time = ''
        this.result = ''
        // get formState
        const cookieValue = VueCookies.get('formState')

        if (!VueCookies.get('token')) {
          notification.open({
            message: 'Error',
            type: 'error',
            description:
              'Please login.',
            duration: 2,
          });
          this.spinning = false

          this.isDisabled = false
          return
        }

        if (cookieValue.area.includes('China')) {
          // const ChinaArea = ['cn_best','cn_law','cn_gbt','cn_policy']
          // this.ask_area_count = ChinaArea.length
          // for (let i = 0; i < ChinaArea.length; i++) {
          //   const model = ChinaArea[i];
          //   this.getAsk(cookieValue,model,ChinaArea.length)
          // }
          const ChinaArea = ['cn_index_multi_doc_graph']
          this.getNewAsk(cookieValue,ChinaArea[0])
        }
      },
      isMobile(){ 
        const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i;
        return regex.test(window.navigator.userAgent);
      }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#result {
  text-align: left;
}
#time {
  margin: 20px 0 0;
}
#slogan {
  margin: 20px 0 0;
}
#search {
  margin: 40px 0 30px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}

.card-demo-custom-area {
  cursor: pointer;
  margin: 0 auto;
  width: 500px;
}
.index h1 {
  text-align: center;
}
.slogan {
  text-align: center;
}
</style>
